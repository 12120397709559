import tw, { styled } from "twin.macro"
import * as Common from "../../common/styles"

export const Container = Common.Container

export const Section = styled(Common.Section)`
  ${tw`bg-gray-100 pb-24`}
`

export const SectionSubTitle = Common.SectionSubTitle
export const SectionTitle = styled(Common.SectionTitle)`
  ${tw`md:mb-16`}
`
export const SectionHeader = Common.SectionHeader
export const SectionText = styled(Common.SectionText)`
  ${tw`text-left max-w-full md:text-base`}
`

export const Heading = styled.h3`
  ${tw`text-2xl text-primary text-left pt-4 mb-4`}
`

export const Image = tw.img`shadow mb-8 block w-full`

export const Box = styled.div`
  ${tw`rounded-xl shadow px-6 md:px-10 py-6 max-w-3xl md:py-8 mx-auto my-8 bg-white leading-relaxed`}
  p:not(last-of-type) {
    ${tw`mb-2`}
  }
`
export const Grid = styled.div`
  ${tw`grid grid-cols-7 gap-4`}

  strong {
    ${tw`col-span-2 text-right`}
  }
  span {
    ${tw`text-left col-span-5`}
  }
`

export const List = styled.ol`
  ${tw`list-inside list-decimal	 text-left leading-relaxed text-gray-500 mt-4 `}
  ol {
    ${tw`ml-4 md:ml-8  mt-2 `}
  }
  li {
    ${tw`mb-1 `}
  }
`

export const Tabs = tw.div`flex justify-center gap-4 mb-4`
export const Tab = styled.div`
  ${tw`px-4 py-2 text-center bg-white border-primary font-medium cursor-pointer rounded-full shadow`}
  ${(props) => props.active && tw`bg-primary text-white`}
`

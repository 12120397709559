import React, { useState } from "react"

import AddUser from "../../images/add-user.jpg"
import AddUserGA4 from "../../images/add-user-ga4.png"
import Settings from "../../images/settings.jpg"
import SettingsGA4 from "../../images/settings-ga4.png"
import UserSettingsGA4 from "../../images/user-settings.png"
import UserSettings2GA4 from "../../images/user-settings-2.png"
import ID from "../../images/id.jpg"

import * as Styled from "./styles"

export default function Setup() {
  const [tab, setTab] = useState("ua")

  return (
    <Styled.Section>
      <Styled.Container>
        <Styled.SectionHeader>
          <Styled.SectionSubTitle>Setup</Styled.SectionSubTitle>
          <Styled.SectionTitle>CO2計測用設定の手順</Styled.SectionTitle>
          <Styled.SectionText>
            CO2排出量を計測するのにGoolgeアナリティクスの情報を使用しています。
            <br />
            下記の通り、当社にアクセス情報閲覧の権限を与えてください。
          </Styled.SectionText>
          <Styled.Tabs>
            <Styled.Tab active={tab === "ua"} onClick={() => setTab("ua")}>
              Universal Analytics
            </Styled.Tab>
            <Styled.Tab active={tab === "ga4"} onClick={() => setTab("ga4")}>
              GA4
            </Styled.Tab>
          </Styled.Tabs>
          {tab === "ua" ? (
            <div>
              <Styled.Heading>
                1. 該当するウェブサイトのGoogleアナリティクスを開きます。
              </Styled.Heading>
              <Styled.Heading>
                2.
                管理画面左下の「管理」で設定を開いて、フィルタなどがかかっていないビューを選択します。
              </Styled.Heading>
              <Styled.SectionText>
                初期設定で作られる「すべてのウェブサイトデータ」の設定を変更されてない場合はこちらを推奨します。
              </Styled.SectionText>
              <Styled.Image src={Settings} />
              <Styled.Heading>
                3. 「ビューのアクセス管理」をクリックします。
              </Styled.Heading>
              <Styled.Heading>
                4. 下記の通り、ユーザーを追加します。
              </Styled.Heading>
              <Styled.Box>
                <Styled.Grid>
                  <strong>メールアドレス</strong>
                  <span>analytics@cnwapp.iam.gserviceaccount.com</span>
                  <strong>権限</strong>
                  <span>表示と管理</span>
                </Styled.Grid>
              </Styled.Box>
              <Styled.Image src={AddUser} />
              <Styled.Heading>
                5. 「管理」の画面に戻って「ビューの設定」をクリックします。
              </Styled.Heading>
              <Styled.Heading>
                6.
                「ビューID」と該当するウェブサイトURLを営業担当にお知らせください。
              </Styled.Heading>
              <Styled.Image src={ID} />
            </div>
          ) : (
            <div>
              <Styled.Heading>
                1. 該当するウェブサイトのGoogleアナリティクスを開きます。
              </Styled.Heading>
              <Styled.Heading>
                2. 管理画面左下の「管理」で設定を開きます。
              </Styled.Heading>
              <Styled.Image src={SettingsGA4} />
              <Styled.Heading>
                3. 「プロパティのアクセス管理」をクリックします。
              </Styled.Heading>
              <Styled.Heading>
                4. 下記の通り、ユーザーを追加します。
              </Styled.Heading>
              <Styled.Box>
                <Styled.Grid>
                  <strong>メールアドレス</strong>
                  <span>analytics@cnwapp.iam.gserviceaccount.com</span>
                  <strong>直接の役割とデータ制限</strong>
                  <span>閲覧者</span>
                </Styled.Grid>
              </Styled.Box>
              <Styled.Image src={AddUserGA4} />
              <Styled.Heading>
                5.
                追加したユーザーの右側の「︙」をクリックして、「ユーザーのアカウントの詳細を…」をクリックします。
              </Styled.Heading>
              <Styled.Image src={UserSettingsGA4} />
              <Styled.Heading>
                6.
                下記のように設定されていることを確認し、IDを営業担当にお知らせください。
              </Styled.Heading>
              <Styled.Image src={UserSettings2GA4} />
            </div>
          )}
          <Styled.SectionText>
            上記が完了しましたら、弊社にてアクセス権限を確認し、計測を進めていきます。
          </Styled.SectionText>
        </Styled.SectionHeader>
      </Styled.Container>
    </Styled.Section>
  )
}
